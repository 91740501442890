import { DeepPartial } from "pages/expenses/types";
import { GlobalStore, INITIAL_GLOBAL_STORE } from "../reducer";
import { ExpensesStoreContext } from "../store";
import { INITIAL_CONFIGURATION } from "../constants";

export const MockPermissions = {
  ...INITIAL_CONFIGURATION.permissions,
};

export const MockedExpensesProvider: React.FC<{
  providerProps?: DeepPartial<GlobalStore>;
  children: React.ReactElement;
}> = ({ children, providerProps }) => {
  const store = Object.assign({}, INITIAL_GLOBAL_STORE, providerProps);

  return (
    <ExpensesStoreContext.Provider
      value={{
        store,
        dispatch: () => null,
      }}
    >
      {children}
    </ExpensesStoreContext.Provider>
  );
};
